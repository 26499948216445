
import { defineComponent, ref, PropType, onMounted, inject } from "vue";
import { useRouter } from "vue-router";

//  utils
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import { nextTick } from "q";
import $ from "jquery";


export default defineComponent({
  name: "product",
  setup() {
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    const router = useRouter();
    const DPnumber = 1;
    const DPzufen = ref("");
    const DPnondu = "仪器定制";
    const DUname = ref<string>(userInfo.realName);
    const DPguige = "仪器定制";
    const DUcontact = ref<string>(userInfo.mobile);
    const code = ref("");

    // const showRedPackage = ref(false);
    function handlePostAdd() {
      nextTick(() => {
        if (DPzufen.value.length < 1) {
          Toast({
            title: "请输入待定制仪器产品的名称",
            type: "warning",
          });
          $("#DPzufen").focus();
          return false;
        }
        if (DPzufen.value.length > 100) {
          Toast({
            title: "产品的名称不能超过100个字符",
            type: "warning",
          });
          $("#DPzufen").focus();
          return false;
        }

        if (userInfo.mobile) {
          if (!userInfo.realName) {
            DUname.value = userInfo.nickName
          }
        } else {
          if (
            (DUname.value && DUname.value.length < 2 && DUname.value.length > 15) ||
            !DUname.value
          ) {
            Toast({
              title: "请输入合法姓名",
              type: "warning",
            });
            $("#DUname").focus();
            return false;
          }
        }

        if (userInfo.mobile) {
          DUcontact.value = userInfo.mobile
        } else {
          if ((DUcontact.value && DUcontact.value.length > 20) || (DUcontact.value && DUcontact.value.length < 1) || !DUcontact.value) {
            Toast({
              title: "请输入合法联系方式",
              type: "warning",
            });
            $("#DUcontact").focus();
            return false;
          }
        }

        if (!code.value && code.value.length < 1) {
          Toast({
            title: "请输入验证码",
            type: "warning",
          });
          $("#code").focus();
          return false;
        }


        const formData = new FormData();
        formData.append("DPnumber", "1");
        formData.append("DPnondu", DPnondu);
        formData.append("DUname", DUname.value);
        formData.append("DPzufen", DPzufen.value);
        formData.append("DPguige", DPguige);
        formData.append("DUcontact", DUcontact.value);
        formData.append("Code", code.value);
        api
          .post("/M/Server/DoSubmitDingzhi", formData)
          .then((res) => {
            if (res.data.success) {
              Toast({
                title: res.data.msg,
                type: "success",
                onClose: () => {
                  // router.push("/product");
                },
              });
              // showRedPackage.value = true;
            //   DPnumber.value = "";
              DPzufen.value = "";
            //   DPnondu.value = "";
              DUname.value = "";
            //   DPguige.value = "";
              DUcontact.value = "";
              code.value = "";
            } else {
              Toast({
                title: res.data.msg,
                type: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }

    const codeImg = ref("/M/Server/GetVerifyCodeImg");

    function getCodeImg() {
      codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
    }

    function handleBack() {
      if (window.history.length <= 1) {
        router.push({ path: "/" });
      } else {
        router.back();
      }
    }

    // function onOpenPackage() {
    //   showRedPackage.value = false;
    // }
    return {
      DPnumber,
      DPnondu,
      DUname,
      DPzufen,
      DPguige,
      DUcontact,
      // DPbeizhu,
      code,
      handleBack,
      handlePostAdd,
      codeImg,
      getCodeImg,
      // onOpenPackage,
      // showRedPackage,
      userInfo
    };
  },
});
